import styled from '@emotion/styled'
import { Rumble } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  languageCode: string
  rooms: RoomProps[]
}

export const RoomsPreview = memo(function RoomsPreview({
  languageCode,
  rooms,
}: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      <Title>
        <Rumble />
        {useVocabularyData('all-our-rooms', languageCode)}
      </Title>

      <Wrapper>
        {rooms.map((item, index) => (
          <Room key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.secondaryLight};
  padding-top: 3rem;
  text-align: center;
  &:after {
    content: '';
    display: block;
    height: 6.25rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0 auto 3.125rem;
  padding: 0 2rem;
  text-transform: uppercase;

  svg {
    display: block;
    width: auto;
    height: 1.75rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 0 auto 0.875rem;
  }
`

const Wrapper = styled.div``
