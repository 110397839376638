import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Mq, Users } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  pax?: string
  roomsPageURL?: string
  size?: string
  title?: string
}

export const Room = memo(function Room({
  image,
  pax,
  roomsPageURL,
  size,
  title,
}: Props) {
  return (
    <Container to={roomsPageURL ? roomsPageURL : ''}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      <Wrapper>
        {title ? <Title>{title}</Title> : null}

        {pax || size ? (
          <Info dial={5} row wrap>
            {size ? (
              <Size dial={4} row>
                <Mq />
                {size}
              </Size>
            ) : null}
            {pax ? (
              <Pax dial={4} row>
                <Users />
                {pax}
              </Pax>
            ) : null}
          </Info>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  display: block;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  margin-top: 0.125rem;
  padding: 5.3125rem 2rem;
  position: relative;
  &:first-of-type {
    margin-top: 0;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.3s ease-in-out;
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-transform: uppercase;
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.9375rem;
  margin-top: 1.125rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 1.125rem;
    fill: none;
    margin-right: 0.75rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
  }

  div {
    margin: 0 1.3125rem;
  }
`

const Size = styled(FlexBox)``

const Pax = styled(FlexBox)``
